/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Switch } from 'formik-antd';
import { Col, message, Modal, Popconfirm, Spin, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import Box from '~/components/Box';
import FormControl from '~/components/Form/FormControl';
import { decrypt, getColorByAbstractStatus } from '~/Utils';
import { ModalFooter } from '~/components/Modal';
import api from '~/services/api';
import { Table, TableActions } from '~/components/Table';
import Button from '~/components/Button';
import { FaChevronDown, FaChevronUp, FaPencilAlt, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { DivTitle } from '~/pages/Person/styles';

export default function AbstractForm({ visible, onClose, resume }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState({});
  const [loading, setLoading] = useState(false);
  const [authorsList, setAuthorList] = useState([]);
  const [actualAuthor, setActualAuthor] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      setRecordData(resume);
      setAuthorList(resume.authors);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    values.authors = authorsList
      .filter(a => a) // Remove índices vazios
      .map((author, index) => ({
        ...author,
        author_order: index + 1, // Define a ordem como índice + 1
      }));

    setLoading(true);
    try {
      if (values.id) {
        await api.put(`/resumes/${values.id}`, values);
        message.success(t('messages:abstracts:updated'));
      }
      onClose();
    } catch (error) {
      if (error.response.status === 400) {
        message.error(t('messages:anErrorOcurred'));
      }
      const errors = errorHandler(error);
      setErrors(errors);
    }
    setLoading(false);
  };

  function transformToScientificName(name) {
    // Divide o nome em partes
    const nameParts = name.trim().split(/\s+/);

    // Separa o último nome (sobrenome) das iniciais
    const lastName = nameParts.pop();
    const initials = nameParts.map(part => `${part[0].toUpperCase()}.`);

    // Junta as iniciais e o sobrenome
    return `${lastName}, ${initials.join(' ')}`;
  }

  const validate = values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Nome campo obrigatorio';
    }

    if (values.name && values.name.length < 3) {
      errors.name = 'Nome deve ser no mínimo 3';
    }

    if (!values.scientific_name) {
      errors.scientific_name = 'Nome Cientifíco campo obrigatorio';
    }

    if (!values.institution) {
      errors.institution = 'Instituição campo obrigatorio';
    }

    if (values.institution && values.institution.length < 3) {
      errors.institution = 'Instituição deve ser no mínimo 3';
    }

    return errors;
  };

  const handleNewAuthor = async values => {
    setLoading(true);
    try {
      // NOTE: if authors has just one element, it's the presenter, uncomment the line below to allow only one presenter
      // if (authors && authors.length === 0) {
      //   values.presenter = true;
      // }
      if (authorsList?.length < 10) {
        setAuthorList(prevValues => [...prevValues, values]);
      } else {
        message.warning(t('messages:abstracts:allowedOnlyFiveAuthors'));
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEditAuthor = async values => {
    setLoading(true);
    try {
      // Mapeia a lista de autores, substituindo o autor que foi editado
      const updatedAuthorsList = authorsList.map(author => {
        // Se o nome do autor corresponde ao que está sendo editado, substitui pelos novos valores
        if (author.name === actualAuthor.name) {
          return { ...author, ...values }; // Retorna o autor atualizado
        }
        return author; // Retorna o autor sem alterações
      });

      // Atualiza a lista de autores com a lista modificada
      setAuthorList(updatedAuthorsList);
    } catch (error) {
      // Lida com possíveis erros
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDeleteAuthor = async index => {
    const authorToDelete = authorsList[index];
    if (authorToDelete.presenter === true) {
      message.warning(t('messages:abstracts:deletePresenter'));
      return;
    }

    setLoading(true);
    try {
      const authors = authorsList;
      await setAuthorList([]);
      delete authors[index];
      await setAuthorList(authors);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const moveUp = index => {
    if (index === 0) return; // Não pode mover o primeiro item para cima
    const newList = [...authorsList];
    // Troca o item atual com o item anterior
    [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
    setAuthorList(newList);
  };

  // Função para mover um item para baixo
  const moveDown = index => {
    if (index === authorsList.length - 1) return; // Não pode mover o último item para baixo
    const newList = [...authorsList];
    // Troca o item atual com o próximo item
    [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
    setAuthorList(newList);
  };

  const authorsTableColumns = [
    {
      title: t('screens:abstracts.authors.order'),
      dataIndex: 'order',
      key: 'order',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '150px', // Ajuste a largura conforme necessário
      align: 'center',
    },
    {
      title: t('screens:abstracts.authors.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:abstracts.authors.scientific_name'),
      dataIndex: 'scientific_name',
      key: 'scientific_name',
    },
    {
      title: t('screens:abstracts.authors.institution'),
      dataIndex: 'institution',
      key: 'institution',
    },
    {
      title: t('screens:abstracts.authors.presenter'),
      dataIndex: 'presenter',
      key: 'presenter',
      render: (text, record) => (
        <Switch
          disabled
          checked={record.presenter}
          onClick={() => {
            const authors = authorsList.map(author => ({
              ...author,
              presenter: author === record,
            }));
            setAuthorList(authors);
          }}
        />
      ),
    },
    {
      title: t('screens:abstracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record, index) => (
        <TableActions>
          <>
            <Button size="small" title="Editar" onClick={() => setActualAuthor(record)}>
              <FaPencilAlt />
            </Button>

            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDeleteAuthor(index)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
            <Button
              disabled={index === 0}
              onClick={() => moveUp(index)}
              style={{ width: '5px', border: '1px solid #dddddd', marginRight: '5px' }}
              color="green"
            >
              <FaChevronUp />
            </Button>
            <Button
              disabled={index === authorsList.length - 1}
              onClick={() => moveDown(index)}
              style={{ width: '5px', border: '1px solid #dddddd' }}
            >
              <FaChevronDown />
            </Button>
          </>
        </TableActions>
      ),
    },
  ];

  const abstractSchema = Yup.object().shape({
    congress_id: Yup.string().required(),
    subarea_id: Yup.string().required(),
    resume: Yup.string().required(),
    title: Yup.string().required(),
    word_1: Yup.string().required(),
    word_2: Yup.string().nullable(),
    word_3: Yup.string().nullable(),
    word_4: Yup.string().nullable(),
    word_5: Yup.string().nullable(),
    acknowledgement: Yup.string().required(),
    sponsors: Yup.string().nullable(),
    uf: Yup.string().required(),
  });

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    console.log(actualAuthor);
  }, [actualAuthor]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSave}
      initialValues={recordData}
      validationSchema={permissions.includes('@superAdmin') ? abstractSchema : false}
      enableReinitialize
    >
      {({ errors, isSubmitting, resetForm, submitForm }) => (
        <Modal
          title={`${t('screens:viewing')} - #${recordData.id} - ${t('screens:abstracts.sent_at')} ${new Date(
            recordData.created_at
          ).toLocaleString()}`}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="70%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@congress/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Box>
                <Row>
                  <FormControl cols={{ xl: 3, xs: 16, sm: 12 }} label={t('screens:abstracts.data.status')}>
                    <Tag color={getColorByAbstractStatus(recordData.status)}>{recordData.status}</Tag>
                  </FormControl>
                </Row>
                {recordData.evaluator_observation && (
                  <Row>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.evaluator_observation}
                      field="evaluator_observation"
                      label={t('screens:abstracts.data.evaluator_observation')}
                    >
                      <Input.TextArea disabled={!permissions.includes('@superAdmin')} name="evaluator_observation" />
                    </FormControl>
                  </Row>
                )}
                <Row>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 12 }}
                    error={errors.congress_id}
                    label={t('fields:congress_id')}
                  >
                    <Input name="congress.description" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 12 }}
                    error={errors.subarea_id}
                    label={t('fields:subarea_id')}
                  >
                    <Input name="subArea.name" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 24 }}
                    disabled={!permissions.includes('@superAdmin')}
                    error={errors.title}
                    label={t('screens:abstracts.data.title')}
                  >
                    <Input name="title" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 24 }}
                    error={errors.resume}
                    field="resume"
                    label={t('screens:abstracts.data.abstract')}
                  >
                    <Input.TextArea rows={5} name="resume" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_1}
                    label={`${t('screens:abstracts.data.word')} 1`}
                  >
                    <Input name="word_1" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_2}
                    label={`${t('screens:abstracts.data.word')} 2`}
                  >
                    <Input name="word_2" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_3}
                    label={`${t('screens:abstracts.data.word')} 3`}
                  >
                    <Input name="word_3" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_4}
                    label={`${t('screens:abstracts.data.word')} 4`}
                  >
                    <Input name="word_4" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_5}
                    label={`${t('screens:abstracts.data.word')} 5`}
                  >
                    <Input name="word_5" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.uf}
                    label={t('screens:abstracts.data.uf')}
                  >
                    <Input name="uf" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                </Row>

                <Row>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 24 }}
                    error={errors.acknowledgement}
                    label={t('screens:abstracts.data.acknowledgement')}
                  >
                    <Input name="acknowledgement" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 24 }}
                    error={errors.sponsors}
                    label={t('screens:abstracts.data.sponsors')}
                  >
                    <Input name="sponsors" disabled={!permissions.includes('@superAdmin')} />
                  </FormControl>
                </Row>
                <Formik validateOnBlur={false} validateOnChange={false} initialValues={actualAuthor} enableReinitialize>
                  {({ values, setErrors, setValues }) => (
                    <Spin spinning={loading}>
                      <div>
                        <Input type="hidden" name="id" />
                        <Box>
                          <DivTitle>{t('screens:abstracts.authors.title')}</DivTitle>
                          <Row>
                            <FormControl
                              cols={{ xl: 6, xs: 24, sm: 12 }}
                              error={errors.name}
                              label={t('screens:abstracts.authors.name')}
                              field="name"
                              required
                            >
                              <Input
                                name="name"
                                maxLength={255}
                                onBlur={() => {
                                  const abreviate = transformToScientificName(values.name); // const abreviate = handleAbreviate(values.name);
                                  if (!values.scientific_name) {
                                    setValues({ ...values, scientific_name: abreviate });
                                  }
                                }}
                              />
                            </FormControl>
                            <FormControl
                              cols={{ xl: 6, xs: 24, sm: 12 }}
                              error={errors.scientific_name}
                              field="scientific_name"
                              label={t('screens:abstracts.authors.scientific_name')}
                              required
                            >
                              <Input name="scientific_name" maxLength={255} />
                            </FormControl>
                            <FormControl
                              cols={{ xl: 6, xs: 24, sm: 12 }}
                              error={errors.institution}
                              field="institution"
                              label={t('screens:abstracts.authors.institution')}
                              required
                            >
                              <Input name="institution" maxLength={255} />
                            </FormControl>
                            {/* <FormControl
                              cols={{ xl: 2, xs: 24, sm: 6 }}
                              error={errors.presenter}
                              field="presenter"
                              label={t('screens:abstracts.authors.presenter')}
                              required
                            >
                              <Switch name="presenter" disabled={canPersonEdit() === false} />
                            </FormControl> */}
                            <Col xl={2} xs={24} sm={6} className="author-actions">
                              {actualAuthor?.name?.length > 0 ? (
                                <Button
                                  style={{ marginTop: '25px' }}
                                  color="primary"
                                  onClick={() => {
                                    const error = validate(values);
                                    setErrors(error);
                                    if (Object.keys(error).length === 0) {
                                      handleEditAuthor(values);
                                      resetForm();
                                      setActualAuthor([]);
                                    }
                                  }}
                                >
                                  Salvar
                                </Button>
                              ) : (
                                <Button
                                  style={{ marginTop: '25px' }}
                                  color="primary"
                                  onClick={() => {
                                    const error = validate(values);
                                    setErrors(error);
                                    if (Object.keys(error).length === 0) {
                                      handleNewAuthor(values);
                                      resetForm();
                                      setActualAuthor([]);
                                    }
                                  }}
                                >
                                  <FaPlus />
                                </Button>
                              )}
                            </Col>
                          </Row>
                          <Table
                            pagination={authorsList.length > 10}
                            pageSize={10}
                            rowKey={record => `${record?.id}.${record?.name}`}
                            loading={loading}
                            dataSource={authorsList}
                            columns={authorsTableColumns}
                          />
                        </Box>
                      </div>
                    </Spin>
                  )}
                </Formik>
              </Box>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
